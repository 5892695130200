import React from 'react';
import {
  Fixed,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="December 2019"
      subnav="release-notes">
      <div id="December2019" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          This month's release is a bit early due to the holiday season, but
          there's still something to report! We moved the data table from the
          naughty to the nice list, read how below.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="3.34.3"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v3.34.3"
            />
            <div className={styles.changesList} />
            <Fixed>
              An issue when the first column was unable to be sorted.
            </Fixed>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
